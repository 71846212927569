<template>
    <div class="comment_input_box p15" v-if="$user">
        <div v-if="Preloader">
            <div v-if="form.file" class="img_preloader">
                <b-img :src="img" alt="Предзагрузка фото"></b-img>
                <b-button class="btn-del btn-danger" @click="form.file = null">X</b-button>
            </div>
            <form @submit.prevent="send">
                <div class="upload-btn-wrapper">
                    <b-form-file v-model="form.file" placeholder="" drop-placeholder="" accept="image/jpeg, image/png" plain class="file-upload"></b-form-file>
                    <button class="btn_file"><img class="file_img" src="/images/icon/paperclip.svg" alt="Фото" />
                    </button>
                </div>
                <textarea type="text" class="main_input" v-model="form.text" cols="30" rows="2" v-autogrow></textarea>
                <button class="btn-send_box" type="submit">
                    <img class="btn-send" src="/images/icon/send.svg" alt="Отправить" />
                </button>
            </form>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
    <div v-else class="p15">
        <UserLogin :route="$route.fullPath" class="text-center" />
    </div>
</template>
<script>
import UserLogin from '@/components/App/Login/Login';
export default {
    name: "CommentsForm",
    components: {
        UserLogin,
    },
    data() {
        return {
            Preloader: 'finish',
            form: {
                file: null,
                text: '',
            },
            img: null,
        }
    },
    methods: {
        send() {
            this.Preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/Communication.post';
            let form = new FormData();
            form.set('post', this.form.text);
            if (this.form.file) form.set('pic', this.form.file);
            this.$http.post(url, form).then(() => {
                this.$emit('onChange');
            }).finally(() => {
                this.formReset();
                this.Preloader = 'finish';
            });
        },
        formReset() {
            this.form.file = null;
            this.form.text = '';
        },
    },

    watch: {
        'form.file': function() {
            if (this.form.file) {
                let image = new Image();
                image.src = URL.createObjectURL(this.form.file);
                image.onload = () => {
                    if (image.width > 149 && image.height > 149) {
                        this.img = URL.createObjectURL(this.form.file);
                    } else {
                        this.form.file = null;
                        alert('Минимальный размер картинки 150х150 пикселей');
                    }

                }
            }
        }
    },
}
</script>
<style scoped>
@media only screen and (max-width: 450px) {
    .login {
        font-size: 12px;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .login {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .login {
        font-size: 16px;
    }
}

textarea {
    min-height: 100px;
}

.login {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.5rem;
    margin-bottom: 10px;
}

.login a {
    text-decoration: none;
}

.comment_input_box form {
    position: relative;
}

.commentFile {
    position: absolute;
    top: 7px;
    left: 8px;
    color: #4a76a8;
    max-height: 10px;

}

.img_preloader {
    position: relative;
    display: flex;
    margin-bottom: 5px;
}

.img_preloader button {
    height: 100px;
}

.img_preloader img {
    max-height: 100px;
    max-width: 250px;
}

.btn-send_box {
    width: 45px;
    height: 45px;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.btn-send {
    height: 30px;
    width: 30px;
}

.upload-btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;

}

.btn_file {
    width: 45px;
    height: 45px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background: transparent;
}

.file_img {
    height: 30px;
    width: 30px;
}

.btn-del {
    height: 100%;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
}

.main_input {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 0;
}
</style>
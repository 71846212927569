<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Городская лента - {{ $myCity.name }}</h1>
            </div>
            <div class="default_box_content">
                <button class="button button_default" v-b-modal.modal>Добавить запись</button>
                <b-modal ref="modal" id="modal" centered title="Добавить запись" hide-footer size="lg" body-class="p-15">
                    <CommunicationForm @onChange="api(); hideModal()"/>
                </b-modal>
                <div v-if="preLoad">
                    <div v-for="(item, num) in dataList" :key="item.id">
                        <CommunicationListCard :item="item" :users.sync="users" @onChange="api"></CommunicationListCard>
                        <AdsPlaginNew v-if="num !== 0 && num % 3 === 0" :direction="'horizontal'" class="main_plagin" />
                    </div>
                    <div v-if="button_preloader">
                        <button v-if="page < totalPages" class="load_next_button" @click="api_next()">Показать ещё</button>
                    </div>
                    <div v-else>
                        <PreLoadBox />
                    </div>
                </div>
                <div v-else>
                    <PreLoadBox />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import CommunicationListCard from "./CommunicationList/Card";
import AdsPlagin from "@/components/Ads/AdsPlagin";
import CommunicationForm from "./CommunicationForm";
export default {
    name: "CommunicationList",
    components: {
        AdsPlagin,
        CommunicationListCard,
        CommunicationForm
    },
    metaInfo() {
        return {
            title: this.dataList && this.dataList.length ? `Городская лента - ` : null,
            meta: [
                { name: 'description', itemprop: 'description', content: `Городская лента в городе ${this.$myCity.name}. Общение, знакомства.` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Городская лента - ${this.$myCity.name}` },
                { property: 'og:description', content: `Городская лента в городе ${this.$myCity.name}. Общение, знакомства.` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            button_preloader: 'finish',
            preLoad: 'finish',
            form: {
                file: null,
                text: '',
            },
            img: null,
            totalPages: 100,
            dataList: [],
            users: {},
            page: 1,
        }
    },
    methods: {
        api() {
            this.preLoad = null;
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Communication.getList';
            this.$http.get(url, {
                params: {
                    page: this.page
                }
            }).then((response) => {
                this.dataList = response.data.response.list;
                this.totalPages = response.data.response.link.total_pages;
                this.users = response.data.response.users;
            }).finally(() => {
                this.preLoad = 'finish';

            })
        },
        api_next() {
            this.button_preloader = null;
            let url = this.$config.api_url + this.$route.params.city + '/Communication.getList';
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page
                }
            }).then((response) => {
                this.dataList = this.dataList.concat(response.data.response.list);
                this.totalPages = response.data.response.link.total_pages;
                this.users = response.data.response.users;
            }).finally(() => {
                this.button_preloader = 'finish';

            })
        },
        hideModal() {
            this.$refs['modal'].hide();
        },

    },
    mounted() {
        this.api();

    },

}
</script>
<style scoped>
.comment_input_box form {
    position: relative;
}

.main_plagin {
    margin-top: 20px;
}

/* FORM */
.communication_form_box {
    background: #fff;
    border-radius: 6px;
}

.img_preloader {
    position: relative;
    display: flex;
    margin-bottom: 5px;
}

.img_preloader button {
    height: 100px;
}

.img_preloader img {
    max-height: 100px;
    max-width: 250px;
}

.input_box {
    position: relative;
}

.btn-send_box {
    width: 45px;
    height: 45px;
    border: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.btn-send {
    height: 30px;
    width: 30px;
    background: #fff;
}

.upload-btn-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    overflow: hidden;
    z-index: 2;

}

.btn_file {
    width: 45px;
    height: 45px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background: transparent;
}

.file_img {
    height: 30px;
    width: 30px;
    background: #fff;
}

.btn-del {
    height: 100%;
}
</style>
<template>
    <div>
        <div v-if="this.ads.length > 0">
            <link rel="prefetch prerender" :href="item.horizontal" />
            <link rel="prefetch prerender" :href="item.vertical" />
            <link rel="prefetch prerender" :href="item.square" />
            <div v-if="loading">
                <b-card no-body class="you_container you_card overflow-hidden" v-if="direction === 'horizontal'">
                    <div v-if="item.poster_id">
                        <router-link :to="{name: 'Post', params: {id: item.poster_id}}">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <b-card-img :src="item.horizontal" class="rounded-0" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <b-card-body>
                                        <b-card-title class="you_title">{{item.name}}</b-card-title>
                                    </b-card-body>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </div>
                    <div v-else-if="item.org_id">
                        <router-link :to="{name: 'OrganisationGet', params: {id: item.org_id}}">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <b-card-img :src="item.horizontal" class="rounded-0" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <b-card-body>
                                        <b-card-title class="you_title">{{item.name}}</b-card-title>
                                    </b-card-body>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </div>
                    <div v-else-if="item.shares_id">
                        <router-link :to="{name: 'Shares', params: {id: item.shares_id}}">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <b-card-img :src="item.horizontal" class="rounded-0" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <b-card-body>
                                        <b-card-title class="you_title">{{item.name}}</b-card-title>
                                    </b-card-body>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </router-link>
                    </div>
                    <div v-else-if="item.url">
                        <a :href="item.url" rel="nofollow" target="_blank">
                            <b-row no-gutters>
                                <b-col md="4" class="align-self-center">
                                    <b-card-img :src="item.horizontal" class="rounded-0" />
                                </b-col>
                                <b-col md="8" class="you_body">
                                    <b-card-body>
                                        <b-card-title class="you_title">{{item.name}}</b-card-title>
                                    </b-card-body>
                                    <div class="marks">реклама</div>
                                </b-col>
                            </b-row>
                        </a>
                    </div>
                </b-card>
                <b-card no-body class="you_container you_card overflow-hidden" v-if="direction === 'vertical'">
                    <b-row no-gutters>
                        <b-col md="4" class="align-self-center">
                            <b-card-img :src="item.vertical" class="rounded-0" />
                        </b-col>
                        <b-col md="8" class="you_body">
                            <b-card-body>
                                <b-card-title class="you_title">{{item.name}}</b-card-title>
                            </b-card-body>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card no-body class="you_container you_card overflow-hidden" v-if="direction === 'square'">
                    <b-row no-gutters>
                        <b-card-img :src="item.square" class="rounded-0" />
                    </b-row>
                </b-card>
                <b-card no-body class="you_card overflow-hidden" v-if="direction === 'left'">
                    <b-row no-gutters>
                        <div v-if="item.poster_id">
                            <router-link :to="{name: 'Post', params: {id: item.poster_id}}">
                                <b-card-img :src="item.vertical" class="rounded-0" />
                            </router-link>
                        </div>
                        <div v-else-if="item.org_id">
                            <router-link :to="{name: 'OrganisationGet', params: {id: item.org_id}}">
                                <b-card-img :src="item.vertical" class="rounded-0" />
                            </router-link>
                        </div>
                        <div v-else-if="item.shares_id">
                            <router-link :to="{name: 'Shares', params: {id: item.shares_id}}">
                                <b-card-img :src="item.vertical" class="rounded-0" />
                            </router-link>
                        </div>
                        <div v-else-if="item.url">
                            <a :href="item.url" rel="nofollow" target="_blank">
                                <b-card-img :src="item.vertical" class="rounded-0" />
                            </a>
                        </div>
                    </b-row>
                </b-card>
            </div>
            <div v-else>
                <PreLoadBox />
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "AdsPlagin",
    props: {
        direction: String
    },
    data() {
        return {
            ads: [],
            item: {},
            loading: 'finish'
        }
    },
    methods: {
        ads_api: function() {
            this.loading = null;
            let url = this.$config.api_url + this.$route.params.city + '/Celat.getList';
            this.$http.get(url).then((response) => {
                this.ads = response.data.response;
                this.item = this.ads[Math.floor(Math.random() * this.ads.length)]

            }).finally(() => {
                this.loading = 'finish';
            })
        }
    },
    mounted() {
        this.ads_api();
    },
    watch: {
        '$route.path': function() {
            this.ads_api();
        }
    }

}
</script>
<style scoped>
a {
    color: #282828;
}

a:hover {
    color: #282828;
    text-decoration: none;
}

.you_container:hover {
    background: #4a76a8;
    transition: .2s;
}

.you_container:hover a,
.you_container:hover .marks {
    color: #fff;
    transition: .2s;
}

.you_card {
    margin-bottom: 10px;
    cursor: pointer;
}

.you_body {
    position: relative;
}

.marks {
    color: #a6a6a6;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 10px;
    border: 1px solid #a6a6a6;
    border-radius: 4px;
}
</style>